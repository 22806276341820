export const About = (props) => {
  return (
    <div id="about">
      <div className="container">

        <div className="row">
          <div className="col-xs-12 col-md-5">
            {" "}
            <img src="img/about.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-7">
            <div className="about-text">
              <h2>Sobre Nosotros</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <p>{props.data ? props.data.mision : "loading..."}</p>
              <p>{props.data ? props.data.vision : "loading..."}</p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-md-9">
            <div className="about-text">
              <h2>Fundador</h2>
              {props.data ? props.data.fundador.map( (t,index) => <p key={index}>{t}</p> ) : <p>loading...</p>}
            </div>
          </div>
          <div className="col-xs-12 col-md-3">
            {" "}
            <img src="img/fundador.jpg" className="img-responsive" alt="" />{" "}
          </div>

        </div>

      </div>
    </div>
  );
};
